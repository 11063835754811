import { render, staticRenderFns } from "./classes.vue?vue&type=template&id=697f30f5&scoped=true"
import script from "./classes.vue?vue&type=script&lang=js"
export * from "./classes.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "697f30f5",
  null
  
)

export default component.exports