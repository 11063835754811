<template>
  <div>
    <Breadcrumbs title="Classes" main="Master Data"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">

              <b-row>
                <b-col cols="12" lg="8" class="m-t-10">
                  <b-input-group>
                    <b-form-input v-model="filter" placeholder="Type to Search" class="border btn-square" autofocus></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''" class="btn btn-square border" variant="primary">Clear</b-button>
                      <b-button v-show="checkUserPermissions(user,['create_classes'])" @click="showCreateModal" class="btn btn-square border" variant="success">New Class</b-button>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-select @change="fetchData" v-model="record_status" :options="statusOptions" value-field="id" text-field="text" outlined></b-select>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
                <b-col cols="12" lg="4" class="m-t-10 text-md-right">
                  <b-form-group label-cols="9" label="Per Page">
                    <b-form-select class="btn-square border text-center" v-model="perPage" :options="pageOptions"></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <div class="table-responsive datatable-vue">
                <b-table
                    ref="table"
                    show-empty
                    selected-variant="success"
                    :items="tableRecords"
                    :fields="tableFields"
                    :filter="filter"
                    :current-page="1"
                    :per-page="perPage"
                    @filtered="onFiltered"
                    @row-selected="rowSelected"
                >
                  <template #cell(action)="row" class="text-right">
                    <b-button v-if="row.item.status > 0" v-show="checkUserPermissions(user,['approve_classes'])" class="btn-square mr-1 ml-1" variant="success" @click="showApproveModal(row.index, row.item, $event.target)">Enable</b-button>
                    <b-button v-else v-show="checkUserPermissions(user,['disapprove_classes'])" class="btn-square mr-1 ml-1" variant="warning" @click="showDisapproveModal(row.index, row.item, $event.target)">Disable</b-button>
                    <b-button v-show="checkUserPermissions(user,['update_classes'])" class="btn-square mr-1 ml-1" variant="success" @click="showEditModal(row.index, row.item, $event.target)">Edit</b-button>
                    <b-button v-show="checkUserPermissions(user,['delete_classes'])" class="btn-square" variant="danger" @click="showDeleteModal(row.index, row.item, $event.target)">Remove</b-button>
                  </template>
                </b-table>
              </div>


              <b-row >
                <b-col cols="4">
                  Records: <strong>{{ totalRows }}</strong>
                </b-col>
                <b-col cols="8" class="my-1" v-if="totalRows">
                  <b-pagination
                      align="right"
                      class="my-0"
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      pills
                  ></b-pagination>
                </b-col>
              </b-row>





              <!-- Begin Approve Modal -->
              <b-modal id="approve_modal" title="Class Status" cancel-variant="default" ok-variant="danger" ok-title="Approve" @hide="clearFormModals" @ok="submitApproveModal">
                <h6 class="m-b-10">Please confirm approval of following class:</h6>
                <strong v-text="selected.name"></strong>
              </b-modal>
              <!-- End Approve Modal -->

              <!-- Begin Approve Modal -->
              <b-modal id="disapprove_modal" title="Class Status" cancel-variant="default" ok-variant="danger" ok-title="Submit" @hide="clearFormModals" @ok="submitDisapproveModal">
                <strong v-text="selected.name"></strong>
                <b-form-group label="Status" class="m-t-10">
                  <b-form-select :options="statusOptions" value-field="id" text-field="text" v-model="selected.status" class="text outline"></b-form-select>
                </b-form-group>
              </b-modal>
              <!-- End Approve Modal -->


              <!-- Begin Delete Modal -->
              <b-modal id="delete_modal" title="Delete" cancel-variant="default" ok-variant="danger" ok-title="Confirm" @hide="clearFormModals" @ok="submitDeleteModal">
                <h6 class="m-b-10">Please confirm removal of following record:</h6>
                <strong v-text="selected.name"></strong>
              </b-modal>
              <!-- End Delete Modal -->

              <!-- Begin Create Modal -->
              <b-modal id="form_modal" size="xl" :title="form_modal_title" cancel-variant="default" ok-variant="primary" :ok-title="form_modal_ok_title" @hide="clearFormModals" @ok="submitFormModal">

                <b-form-group v-if="formModal.id" label="Id">
                  <b-input v-if="form_modal_id === 'form_modal_create'" v-model="formModal.id"></b-input>
                  <b-input v-else v-model="formModal.id" readonly></b-input>
                </b-form-group>

                <b-form-group v-if="formModal.id" label="Name">
                  <b-input v-model="formModal.name" readonly></b-input>
                </b-form-group>


                <div class="row">
                  <div class="col-6 col-lg-4">
                    <b-form-group label="Institute">
                      <b-form-select @change="instituteChanged" :disabled="formModal.id !== null" class="btn-square border" v-model="formModal.institute_id" :options="institutes" value-field="id" text-field="text">
                        <template #first>
                          <b-form-select-option :value="null" disabled>-- Select an option --</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group label="Subject">
                      <b-form-select @change="subjectChanged"  :disabled="!formModal.institute_id" class="btn-square border" v-model="formModal.subject_id" :options="subjects" value-field="id" text-field="text">
                        <template #first>
                          <b-form-select-option :value="null" disabled>-- Select an option --</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group label="Class Year">
                      <b-input type="number" class="btn-square" v-model="formModal.year"></b-input>
                    </b-form-group>
                  </div>
                </div>

                <b-form-group label="Teacher">
                  <b-form-select :disabled="!this.formModal.subject_id" class="btn-square border" v-model="formModal.teacher_id" :options="teachers" value-field="id" text-field="text">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Select an option --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>

                <div class="row">
                  <div class="col-4">
                    <b-form-group label="Class Type">
                      <b-form-select class="btn-square border" v-model="formModal.class_type_id" :options="class_types" value-field="id" text-field="text">
                        <template #first>
                          <b-form-select-option :value="null" disabled>-- Select an option --</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-6 col-lg-4">
                    <b-form-group label="Class Fee">
                      <b-input type="number" class="btn-square" v-model="formModal.class_fee"></b-input>
                    </b-form-group>
                  </div>
                  <div class="col-6 col-lg-4">
                    <b-form-group label="Teacher Percentage">
                      <b-input type="number" class="btn-square" v-model="formModal.teacher_percentage"></b-input>
                    </b-form-group>
                  </div>
                </div>

              </b-modal>
              <!-- End Create Modal -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {checkUserPermissions} from '@/plugins/functions'
import { mapGetters, mapState } from 'vuex'
import CRUD_API from '@/services/crud'
export default {
  name: "classes",
  computed: {
    ...mapState({ user: state => JSON.parse(state.auth.user) }),
    ...mapGetters({
      institutes: 'select/institutes',
      streams: 'select/streams',
      class_types: 'select/class_types',
      // classrooms: 'select/classrooms',
    })
  },
  created() {
    this.$store.dispatch('select/fetchRecords', { id: 'institutes' })
    // this.$store.dispatch('select/fetchRecords', { id: 'classrooms' })
    this.$store.dispatch('select/fetchRecords', { id: 'class-types' })
    this.$store.dispatch('select/fetchRecords', { id: 'streams' })
    this.subjects = []
    this.teachers = []
  },
  data() {
    return {
      filter: '',
      totalRows: 0,
      perPage: 5,
      currentPage: 1,
      pageOptions: [5,10,25,50,100],
      tableRecords: [],
      selected: [],

      subjects: [],
      teachers: [],
      classrooms: [],
      classTypes: [],
      paymentTypes: [],
      api_base: '/api/backend/pages/classes',
      tableFields: [
        // { key: 'id', label: 'ID', sortable: false },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'institute.name', label: 'Institute', sortable: true },
        { key: 'class_fee', label: 'Class Fee', sortable: true, class: 'text-right' },
        { key: 'teacher_percentage', label: 'Teacher %', sortable: false, class: 'text-right' },
        { key: 'students_count', label: 'Student Count', sortable: false, class: 'text-right' },
        { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],


      // Form Modal
      form_modal_id: '',
      form_modal_title: '',
      form_modal_ok_variant: 'primary',
      form_modal_ok_title: '',
      formModal: {
        id: null,
        index: null,
        name: null,
        year: null,
        creator_id: null,
        institute_id: null,
        classroom_id: null,
        stream_id: null,
        subject_id: null,
        teacher_id: null,
        payment_type_id: null,
        class_type_id: null,
        class_fee: 0,
        teacher_percentage: 0,
        status: 0,
      },

      record_status: 0,
      statusOptions: [
        { id: 0, text: "Enabled" },
        { id: 1, text: "Disabled" },
        // { id: 2, text: "Suspended" },
      ],

    }
  },
  watch: {
    currentPage() {
      this.fetchData()
    },
    perPage() {
      this.currentPage = 1
      this.fetchData()
    },
    filter() {
      this.currentPage = 1
      this.fetchData()
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    checkUserPermissions,
    onFiltered(filteredItems) {
      this.currentPage = 1
      this.items.count = filteredItems.length
    },
    rowSelected(item) {
      this.selected = item
    },
    clearSearch() {
      this.filter = ''
      this.currentPage = 1
      this.fetchData()
    },
    handlePageChange(value) {
      //alert(value)
      this.perPage = value
      this.fetchData()
    },
    handlePageSizeChange(value) {
      this.currentPage = value
      this.fetchData()
    },
    async fetchData() {
      await CRUD_API.index( this.api_base, {
        url: this.api_base,
        params: {
          per_page: this.perPage,
          page: this.currentPage,
          filter: this.filter,
          with_student_count: true,
          status: this.record_status
        }
      }).then(function(resp) {
        this.tableRecords = []
        //this.currentPage = resp.data.data.current_page
        //this.perPage = resp.data.data.per_page
        this.totalRows = resp.data.data.total
        this.tableRecords = resp.data.data.data
      }.bind(this)).catch((err) => {
        console.log(err)
        this.$toasted.error("Error occurred while retrieving data")
      }).finally(() => {
        //this.$refs.table.refresh()
      })
    },

    clearFormModals() { this.selected = []; },
    // Create Record Modal
    showCreateModal(button) {
      this.subjects = []
      this.teachers = []
      this.formModal.index = null
      this.formModal.id = null
      this.formModal.name = null
      this.formModal.institute_id = null
      this.formModal.classroom_id = null
      this.formModal.class_type_id = null
      this.formModal.payment_type_id = 1
      this.formModal.stream_id = null
      this.formModal.subject_id = null
      this.formModal.teacher_id = null
      this.formModal.teacher_percentage = 80
      this.formModal.class_fee = 0
      this.formModal.year = new Date().getFullYear()
      this.form_modal_title = "New Class"
      this.form_modal_ok_title = 'Submit'
      this.$root.$emit('bv::show::modal', 'form_modal', button)
    },
    submitFormModal() {
      if (this.formModal.id) {
        this.submitEditModal()
      } else {
        this.submitCreateModal()
      }
    },
    submitCreateModal() {
      CRUD_API.create(this.api_base, this.formModal)
          .then((resp) => {
            this.$toasted.success("Class added successfully")
          })
          .catch((err) => {
            console.log(err)
            this.$toasted.error("Error occurred while saving data")
          })
          .finally(() => {
            this.fetchData()
          })
    },
    // Edit Record Modal
    showEditModal(index, item, button) {
      // TODO:: Stop adding duplicated records
      this.subjects.push({ id: item.subject.id, text: item.subject.name })
      this.teachers.push({ id: item.teacher.id, text: item.teacher.username })
      // this.classrooms.push({ id: item.classroom.id, text: item.classroom.name });

      this.formModal.index = index
      this.formModal.id = item.id
      this.formModal.name = item.name
      this.formModal.institute_id = item.institute_id
      // this.formModal.classroom_id = item.classroom_id
      this.formModal.class_type_id = item.class_type_id
      this.formModal.stream_id = null
      this.formModal.payment_type_id = 1
      this.formModal.subject_id = item.subject_id
      this.formModal.teacher_id = item.teacher_id
      this.formModal.teacher_percentage = item.teacher_percentage
      this.formModal.class_fee = item.class_fee
      this.formModal.year = item.year
      this.form_modal_title = "Edit Class "
      this.form_modal_ok_title = 'Update'
      this.$root.$emit('bv::show::modal', 'form_modal', button)
    },
    submitEditModal(){
      CRUD_API.update(this.api_base, this.formModal.id, this.formModal)
          .then((resp) => {
            console.log(resp)
            this.$toasted.success("Class updated successfully")
          })
          .catch((err) => {
            console.log(err)
            this.$toasted.error("Error occurred while updating data")
          })
          .finally(() => {
            this.fetchData()
          })
    },
    // Delete Record Modal
    showDeleteModal(index, item, button) {
      this.selected = item
      this.$root.$emit('bv::show::modal', 'delete_modal', button)
    },
    submitDeleteModal(){
      CRUD_API.delete(this.api_base, this.selected.id)
          .then((resp) => {
            console.log(resp)
            this.$toasted.success("Class removed successfully")
          })
          .catch((err) => {
            console.log(err)
            this.$toasted.error("Error occurred while removing data")
          })
          .finally(() => {
            this.fetchData()
          })
    },


    instituteChanged(){
      // this.formModal.classroom_id = null
      // this.classrooms = []
      this.formModal.stream_id = null
      this.subjects = [];
      this.formModal.subject_id = null
      this.teachers = [];
      this.formModal.teacher_id = null

      CRUD_API.index('/api/backend/select/subjects', {
        params: {
          institute_id: this.formModal.institute_id
        }
      }).then((resp) => {
        this.subjects = resp.data.data
      })
      CRUD_API.index('/api/backend/select/classrooms', {
        params: {institute_id: this.formModal.institute_id}
      }).then((resp) => {
        this.classrooms = resp.data.data
      })
    },
    streamChanged(){
      this.subjects = [];
      this.subject_id = null
      this.teachers = [];
      this.teacher_id = null
      CRUD_API.index('/api/backend/select/subjects', {
        params: { stream_id: this.stream_id }
      }).then((resp) => {
        this.subjects = resp.data.data
      })
    },
    subjectChanged() {
      this.teachers = [];
      this.teacher_id = null;
      CRUD_API.index('/api/backend/select/teachers', {
        params: { subject_id: this.formModal.subject_id }
      }).then((resp) => {
        this.teachers = resp.data.data
      })
    },


    showApproveModal(index, item, button) {
      this.selected = item
      this.$root.$emit('bv::show::modal', 'approve_modal', button)
    },
    showDisapproveModal(index, item, button) {
      this.selected = item
      this.$root.$emit('bv::show::modal', 'disapprove_modal', button)
    },
    submitApproveModal() {
      this.submitStatusModal(0)
    },
    submitDisapproveModal() {
      this.submitStatusModal(this.selected.status)
    },
    async submitStatusModal(status){
      await CRUD_API.create(this.api_base + '/approve', {
        class_detail_id: this.selected.id,
        status: status
      }).then((resp) => {
        if (resp.success) {
          this.$toasted.success(resp.data.message)
        } else {
          this.$toasted.error(resp.data.message)
        }
      }).catch((err) => {
        console.log(err)
        this.$toasted.error(err.data.message)
      }).finally(() => {
        this.fetchData()
      })
    },

  }
}
</script>

<style scoped>

</style>
